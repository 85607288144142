import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 mb-8 bg-white rounded-lg border border-opacity-70" }
const _hoisted_2 = { class: "text-lg mb-4" }
const _hoisted_3 = { class: "flex flex-wrap -mx-2 sm:-mx-4" }
const _hoisted_4 = { class: "w-full sm:w-6/12 px-2 sm:px-4 mb-4" }
const _hoisted_5 = {
  class: "text-sm mb-2 block",
  for: "buyer-phone"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "w-full sm:w-6/12 px-2 sm:px-4 mb-4" }
const _hoisted_8 = {
  class: "text-sm mb-2 block",
  for: "buyer-email"
}
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "text-xs text-body-muted text-opacity-70" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('contact_info.RecipientContacts')), 1)
    ]),
    _createElementVNode("form", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('contact_info.Phone')), 1),
          _createElementVNode("input", {
            id: "buyer-phone",
            class: "w-full px-3 py-2.5 rounded border placeholder-body lining-nums",
            value: _ctx.userPhoneNumber,
            name: "buyer-phone",
            placeholder: "+998907152525",
            type: "text"
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('contact_info.Email')), 1),
          _createElementVNode("input", {
            id: "buyer-email",
            class: "w-full px-3 py-2.5 rounded border placeholder-body lining-nums",
            value: _ctx.user?.email,
            name: "buyer-email",
            type: "text"
          }, null, 8, _hoisted_9)
        ])
      ]),
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('contact_info.WeWillSendTicketsInformAbout')), 1)
    ])
  ]))
}