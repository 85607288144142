
import {defineComponent, PropType} from "vue";
import {Passenger} from "@/models/order_modes";

export default defineComponent({
  name: "passenger_list",
  props: {
    'passengers': {type: Object as PropType<Passenger[]>},
  },
  data() {
    return {
      showAlert: false,
      showEditForm: false,
      showAddForm: false,

    }
  }
});
