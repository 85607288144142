
import {defineComponent} from "vue";

export default defineComponent({
  name: "payment-form",
  props: ["isOpen", 'paymentUrl'],
  methods: {
    onCloseForm: function () {
      this.$emit('onPaymentFormCloseEvent');
    },
  }
});

